
const en = {
  "Log in": "Log in",
  "Issue Management System": "Issue Management System",
  "Username": "Username",
  "Password": "Password",
  "Log out": "Log out",
  "Tickets": "Tickets",
  "Audiorecords": "Audiorecords",
  "Inbound calls": "Inbound calls",
  "Outbound calls": "Outbound calls",
  "Start date": "Start date",
  "End date": "End date",
  "Today": "Today",
  "Yesterday": "Yesterday",
  "Yesterday+today": "Yesterday+today",
  "Current week": "Current week",
  "Current month": "Current month",
  "Previous week": "Previous week",
  "Previous month": "Previous month",
  "Phone number": "Phone number",
  "Theme": "Theme",
  "Sub theme": "Sub theme",
  "Status": "Status",
  "Change status": "Change status",
  "History of changes": "History of changes",
  "New": "New",
  "In progress": "In progress",
  "Needs clarification": "Needs clarification",
  "Rejected": "Rejected",
  "Closed": "Closed",
  "Product name": "Product name",
  "Production date": "Production date",
  "Batch": "Batch",
  "Manufacturer number": "Manufacturer number",
  "Name": "Name",
  "Phone": "Phone",
  "Call date": "Call date",
  "Agent": "Agent",
  "Critical": "Critical",
  "Yes": "Yes",
  "No": "No",
  "Shop city": "Shop city",
  "Shop name": "Shop name",
  "Shop address": "Shop address",
  "ID": "ID",
  "Product": "Product",
  "City": "City",
  "Description": "Description",
  "Comment": "Comment",
  "Comments": "Comments",
  "Add comment": "Add comment",
  "Show all": "Show all",
  "Hide": "Hide",
  "Write your comment here": "Write your comment here",
  "Call audio record": "Call audio record",
  "added a comment": "added a comment",
  "Change status to:": "Change status to:",
  "Change status?": "Change status?",
  "Are you sure to the status of this task?": "Are you sure to the status of this task?",
  "Ticket": "Ticket",
  "Download": "Download",
  "Play": "Play",
  "Pause": "Pause",
  "Language": "Language",
  "English": "English",
  "Українська": "Ukrainian",
  "Sorry, the page you visited does not exist.": "Sorry, the page you visited does not exist.",
  "Return": "Return",
  "Sorry, you are not authorized to access this page.": "Sorry, you are not authorized to access this page.",
  "Sorry, this page is under construction.": "Sorry, this page is under construction.",
  "Clear all filters": "Clear all filters",
  "Status changes history": "Status changes history",
  "Copy ticket link to clipboard": "Copy ticket link to clipboard",
  "Show test records. Checkbox visible only for admin group.": "Show test records. Checkbox visible only for admin group.",
  "Show test records": "Show test records",
  "Test": "Test",
  "Outbound transfer": "Outbound transfer",
  "Outbound callback": "Outbound callback",
  "Start call": "Start call",
  "End call": "End call",
  "Duration": "Duration",
  "s": "s",
  "Call type": "Call type",
  "Agent number": "Agent number",
  "Start": "Start",
  "End": "End",
  "Agent name": "Agent name",
  "Wait": "Wait",
  "Talk": "Talk",
  "Hold": "Hold",
  "End reason": "End reason",
  "Search": "Search",
  "Last 7 days": "Last 7 days",
  "Call audio record will be available soon.": "Call audio record will be available soon.",
  "The call audio record will be available approximately two hours after its completion.": "The call audio record will be available approximately two hours after its completion.",
  "The caller release the call": "The caller release the call",
  "The system release the call": "The system release the call",
  "The targeted pilot is closed, the call is redirected to an other pilot.": "The targeted pilot is closed, the call is redirected to an other pilot.",
  "The targeted pilot is saturated (its regular queue(s) is saturated), the call is redirected to an other pilot.": "The targeted pilot is saturated (its regular queue(s) is saturated), the call is redirected to an other pilot.",
  "The waiting time overflow timer redirected the call to a pilot or in case of an ACR call, the used selection rule redirected the selection rule redirected the call to a pilot.": "The waiting time overflow timer redirected the call to a pilot or in case of an ACR call, the used selection rule redirected the selection rule redirected the call to a pilot.",
  "The ringing overflow timer redirected the call to a pilot.": "The ringing overflow timer redirected the call to a pilot.",
  "The targeted pilot is closed, the call is redirected to an agent.": "The targeted pilot is closed, the call is redirected to an agent.",
  "The targeted pilot is saturated, the call is redirected to an agent.": "The targeted pilot is saturated, the call is redirected to an agent.",
  "The waiting time overflow timer redirected the call to an agent .": "The waiting time overflow timer redirected the call to an agent .",
  "The ringing overflow timer redirected the call to an agent.": "The ringing overflow timer redirected the call to an agent.",
  "The targeted pilot is closed, the call is redirected to an address (not an acd device).": "The targeted pilot is closed, the call is redirected to an address (not an acd device).",
  "The targeted pilot is saturated, the call is redirected to an address (not an acd device).": "The targeted pilot is saturated, the call is redirected to an address (not an acd device).",
  "The waiting time overflow timer redirected the call to an address (not an acd device).": "The waiting time overflow timer redirected the call to an address (not an acd device).",
  "The ringing overflow timer redirected the call to an address (not an acd device).": "The ringing overflow timer redirected the call to an address (not an acd device).",
  "The call was ringing an agent, another agent of the different processing group picked'up the call.": "The call was ringing an agent, another agent of the different processing group picked'up the call.",
  "The call was ringing an agent when a non monitored device picked?up the call.": "The call was ringing an agent when a non monitored device picked?up the call.",
  "An acd_call was transferred to a pilot by an agent.": "An acd_call was transferred to a pilot by an agent.",
  "An acd_call was transferred to an agent of the same processing group by an agent.": "An acd_call was transferred to an agent of the same processing group by an agent.",
  "An acd_call was transferred to an agent belonging to a different processing group of agent?s one who performed the transfer": "An acd_call was transferred to an agent belonging to a different processing group of agent?s one who performed the transfer",
  "An acd_call was transferred by an agent to an address (non acd device).": "An acd_call was transferred by an agent to an address (non acd device).",
  "A non acd_call was transferred by an agent to a pilot.": "A non acd_call was transferred by an agent to a pilot.",
  "A non acd_call was transferred by an agent to an agent belonging to the same processing group.": "A non acd_call was transferred by an agent to an agent belonging to the same processing group.",
  "A non acd_call was transferred by an agent to an other agent not belonging to the same processing group (or not assigned).": "A non acd_call was transferred by an agent to an other agent not belonging to the same processing group (or not assigned).",
  "A non acd_call was transferred by an agent to an address (not an acd device).": "A non acd_call was transferred by an agent to an address (not an acd device).",
  "A non_acd call ringing an agent was picked?up by a non monitored device.": "A non_acd call ringing an agent was picked?up by a non monitored device.",
  "Communication ends due to an abnormal cause : coupler of agent's set was unplugged, agent?s phone set was unplugged...).": "Communication ends due to an abnormal cause : coupler of agent's set was unplugged, agent?s phone set was unplugged...).",
  "Called released the call (mainly an agent or a non monitored device).": "Called released the call (mainly an agent or a non monitored device).",
  "Acd call rejected. An acd call is presented to a pilot, no resource is available to handle the call (See Note 1).": "Acd call rejected. An acd call is presented to a pilot, no resource is available to handle the call (See Note 1).",
  "The call was ringing an agent, an other agent of the same processing group picked?up the call.": "The call was ringing an agent, an other agent of the same processing group picked?up the call.",
  "An acd call is distributed on a resource which is not an agent.": "An acd call is distributed on a resource which is not an agent.",
  "An acd call is picked?up by a non assigned agent (the call becomes a non?acd call).": "An acd call is picked?up by a non assigned agent (the call becomes a non?acd call).",
  "An acd_call was transferred to a non assigned agent, the call becomes a non acd call": "An acd_call was transferred to a non assigned agent, the call becomes a non acd call",
  "A call was released due to the agent's unplugged phone set": "A call was released due to the agent's unplugged phone set",
  "A call connected to an IVR while waiting in queue (IVR in queue feature) was extracted from the queue by the IVR.": "A call connected to an IVR while waiting in queue (IVR in queue feature) was extracted from the queue by the IVR.",
  "For an ACR call, a selection mode or an attribute list (skill, expert, character) change happened in waiting room.": "For an ACR call, a selection mode or an attribute list (skill, expert, character) change happened in waiting room.",
  "An ACR rotation happened, the new rang agent and the previous one belong to the same PG.": "An ACR rotation happened, the new rang agent and the previous one belong to the same PG.",
  "An ACR rotation happened, the new rang agent and the previous one belong to two different PG.": "An ACR rotation happened, the new rang agent and the previous one belong to two different PG.",
  "Call is suspended.": "Call is suspended.",
  "Ringing rotation for an ACR call distributed to a dedicated pilot": "Ringing rotation for an ACR call distributed to a dedicated pilot",
  "An ACR call sent to a dedicated pilot (ACR networking) is not served and comes back on local node.": "An ACR call sent to a dedicated pilot (ACR networking) is not served and comes back on local node.",
  "An enquiry call is done towards an agent. When the caller releases this enquiry call by a transfert, communication ticket for th": "An enquiry call is done towards an agent. When the caller releases this enquiry call by a transfert, communication ticket for th",
  "Region": "Region",
  "District": "District",
  "Community": "Community",
  "Sector": "Sector",
  "Type": "Type",
  "Category": "Category",
  "Source": "Source",
  "Responsible": "Responsible",
  "Create date": "Create date",
  "PO name": "PO name",
  "ticket created": "ticket created",
  "changed status": "changed status",
  "added comment": "added comment",
  "Users": "Users",
  "Add user": "Add user",
  "First name": "First name",
  "Last name": "Last name",
  "Email": "Email",
  "Register user": "Register user",
  "Search user": "Search user",
  "Confirm password": "Confirm password",
  "New user registration": "New user registration",
  "Please input firts name.": "Please input firts name.",
  "Please input last name.": "Please input last name.",
  "The input is not valid Email.": "The input is not valid Email.",
  "Please input Email.": "Please input Email.",
  "Please input password.": "Please input password.",
  "Password must be at least 8 characters long.": "Password must be at least 8 characters long.",
  "Password must be at most 20 characters long.": "Password must be at most 20 characters long.",
  "Password must contain at least one letter and one number.": "Password must contain at least one letter and one number.",
  "Please confirm your password.": "Please confirm your password.",
  "The new password that you entered do not match.": "The new password that you entered do not match.",
  "Thank you for your application!": "Thank you for your application!",
  "Your application number: ": "Your application number: ",
  "Register another": "Register another",
  "Application registration": "Application registration",
  "Select region": "Select region",
  "Please select your district.": "Please select your district.",
  "Select district": "Select district",
  "Territorial community": "Territorial community",
  "Please select your territorial community.": "Please select your territorial community.",
  "Select territorial community": "Select territorial community",
  "Please select type of application.": "Please select type of application.",
  "Please select category of application.": "Please select category of application.",
  "Please select sector of application.": "Please select sector of application.",
  "Please describe your application.": "Please describe your application.",
  "Public organization name": "Public organization name",
  "Please input your phone number.": "Please input your phone number.",
  "Please input valid phone number in format +380xxxxxxxxx.": "Please input valid phone number in format +380xxxxxxxxx.",
  "Register application": "Register application",
  "Select sector": "Select sector",
  "Please input your name.": "Please input your name.",
  "Please select your region.": "Please select your region.",
  "Check application status": "Check application status",
  "Application ID": "Application ID",
  "Please input application ID.": "Please input application ID.",
  "Format must be two latin letters and six digits (NR123456).": "Format must be two latin letters and six digits (NR123456).",
  "Application status:" : "Application status:",
  "The application with the provided registration number and phone number was not found": "The application with the provided registration number and phone number was not found",
  "Check status": "Check status",
  "Please input phone number.": "Please input phone number.",
  "Please input name." : "Please input name.",
  "Please select community." : "Please select community.",
  "Please select district.": "Please select district.",
  "Please select region." : "Please select region.",
  "Please select sector." : "Please select sector.",
  "Please select type.": "Please select type.",
  "Please select category.": "Please select category.",
  "Edit": "Edit",
  "Save": "Save",
  "Cancel": "Cancel",
  "Social Protection": "Social Protection",
  "Child Protection, Gender-based violence, Protection from Sexual Exploitation and Abuse": "Child Protection, Gender-based violence, Protection from Sexual Exploitation and Abuse",
  "Health": "Health",
  "Nutrition": "Nutrition",
  "Water suplies": "Water suplies",
  "Other": "Other",
  "Vaccination": "Vaccination",
  "Home Visiting Programme": "Home Visiting Programme",
  "Spilno Cash Programme": "Spilno Cash Programme",
  "Spilno Spots": "Spilno Spots",
  "No information": "No information",
  "Complain": "Complain",
  "Gratitude": "Gratitude",
  "Recommendations": "Recommendations",
  "Other": "Other",
  "Service": "Service",
  "Provision": "Provision",
  "Cash": "Cash",
  "Subscription": "Subscription",
  "Education": "Education",
  "Create an Appeal": "Create an Appeal",
  "Leave feedback about our work, services and help." : "Leave feedback about our work, services and help.",
  "Name of non-governmental organization": "Name of non-governmental organization",
  "Add photo": "Add photo",
  "Search by description": "Search by description",
  "Total": "Total",
  "Resolving time": "Resolving time",
  "Status of requests": "Status of requests",
  "Details": "Details",
  "Statuses": "Statuses",
  "Sectors": "Sectors",
  "Statistics": "Statistics",
  "Welcome! You have submitted a request to our UNICEF Ukraine information support hotline.": "Welcome! You have submitted a request to our UNICEF Ukraine information support hotline.",
  "We would appreciate it if you could answer a few questions:": "We would appreciate it if you could answer a few questions:",
  "1. How satisfied are you with the solution or response you received from our team through the hotline?": "1. How satisfied are you with the solution or response you received from our team through the hotline?",
  "2. How likely are you to recommend our hotline for feedback to a friend or colleague? Please rate on a scale from 0 to 10, where “0” means “Not at all likely” and “10” means “Definitely will recommend”.": "2. How likely are you to recommend our hotline for feedback to a friend or colleague? Please rate on a scale from 0 to 10, where “0” means “Not at all likely” and “10” means “Definitely will recommend”.",
  "Very dissatisfied": "Very dissatisfied",
	"Dissatisfied": "Dissatisfied",
	"Neutral": "Neutral",
	"Satisfied": "Satisfied",
	"Very satisfied": "Very satisfied",
  "Please make your choice.": "Please make your choice.",
  "Thank you for your feedback.": "Thank you for your feedback.",
  "You have already left feedback.": "You have already left feedback.",
  "There is no such appeal.": "There is no such appeal.",
  "who provided help (if exists)": "who provided help (if exists)",
  "Please select theme of application.": "Please select theme of application.",
  "Upload": "Upload",
  "Select theme": "Select theme",
  "Sectors and Regions": "Sectors and Regions",
}
   

const ua = {
  "Log in": "Увійти",
  "Issue Management System": "Система управління зверненнями",
  "Username": "Ім'я користувача",
  "Password": "Пароль",
  "Log out": "Вийти",
  "Tickets": "Звернення",
  "Audiorecords": "Аудіозаписи",
  "Inbound calls": "Вхідні дзвінки",
  "Outbound calls": "Вихідні дзвінки",
  "Start date": "Дата початку",
  "End date": "Дата закінчення",
  "Today": "Сьогодні",
  "Yesterday": "Вчора",
  "Yesterday+today": "Вчора+сьогодні",
  "Current week": "Поточний тиждень",
  "Current month": "Поточний місяць",
  "Previous week": "Попередній тиждень",
  "Previous month": "Попередній місяць",
  "Phone number": "Номер телефону",
  "Theme": "Тема",
  "Sub theme": "Підтема",
  "Status": "Статус",
  "Change status": "Змінити статус",
  "History of changes": "Історія змін",
  "New": "Нове",
  "In progress": "В роботі",
  "Needs clarification": "Потребує уточнення",
  "Rejected": "Відхилено",
  "Closed": "Завершено",
  "Product name": "Назва продукту",
  "Production date": "Дата виробництва",
  "Batch": "Партія",
  "Manufacturer number": "Номер виробника",
  "Name": "Ім'я",
  "Phone": "Телефон",
  "Call date": "Дата дзвінка",
  "Agent": "Агент",
  "Critical": "Критичність",
  "Yes": "Так",
  "No": "Ні",
  "Shop city": "Місто магазину",
  "Shop name": "Назва магазину",
  "Shop address": "Адреса магазину",
  "ID": "ID",
  "Product": "Продукт",
  "City": "Місто",
  "Description": "Суть звернення",
  "Comment": "Коментар",
  "Comments": "Коментарі",
  "Add comment": "Додати коментар",
  "Show all": "Показати усі",
  "Hide": "Сховати",
  "Write your comment here": "Напишіть свій коментар тут",
  "Call audio record": "Аудіозапис дзвінка",
  "added a comment": "додав(ла) коментар",
  "Change status to:": "Змінити статус на:",
  "Change status?": "Змінити статус?",
  "Are you sure to the status of this task?": "Ви впевнені, що хочете змінити статус цього звернення?",
  "Ticket": "Звернення",
  "Download": "Завантажити",
  "Play": "Відтворити",
  "Pause": "Пауза",
  "Language": "Мова",
  "English": "Англійська",
  "Українська": "Українська",
  "Sorry, the page you visited does not exist.": "Вибачте, але сторінка, яку ви відвідали, не існує.",
  "Return": "Повернутись",
  "Sorry, you are not authorized to access this page.": "Вибачте, але у вас немає доступу до цієї сторінки.",
  "Sorry, this page is under construction.": "Вибачте, але ця сторінка знаходиться в розробці.",
  "Clear all filters": "Очистити всі фільтри",
  "Status changes history": "Історія зміни статусів",
  "Copy ticket link to clipboard": "Скопіювати посилання на звернення",
  "Show test records. Checkbox visible only for admin group.": "Показати тестові записи. Checkbox видимий тільки для групи адміністраторів.",
  "Show test records": "Показати тестові записи",
  "Test": "Тест",
  "Outbound transfer": "Переключення",
  "Outbound callback": "Передзвони",
  "Start call": "Початок дзвінка",
  "End call": "Кінець дзвінка",
  "Duration": "Тривалість",
  "s": "с",
  "Call type": "Тип дзвінка",
  "Agent number": "Номер агента",
  "Start": "Початок",
  "End": "Кінець",
  "Agent name": "Ім'я агента",
  "Wait": "Очікування",
  "Talk": "Розмова",
  "Hold": "Утримання",
  "End reason": "Причина завершення",
  "Search": "Пошук",
  "Last 7 days": "Останні 7 днів",
  "Call audio record will be available soon.": "Аудіозапис дзвінка буде доступний найближчим часом.",
  "The call audio record will be available approximately two hours after its completion.": "Аудіозапис дзвінка буде доступний приблизно через дві години після його завершення.",
  "The caller release the call": "Абонент завершив дзвінок",
  "The system release the call": "Система завершила дзвінок",
  "The targeted pilot is closed, the call is redirected to an other pilot.": "Цільовий пілот закритий, дзвінок перенаправляється на інший пілот.",
  "The targeted pilot is saturated (its regular queue(s) is saturated), the call is redirected to an other pilot.": "Цільовий пілот переповнений (його звичайна черга(и) переповнена), дзвінок перенаправляється на інший пілот.",
  "The waiting time overflow timer redirected the call to a pilot or in case of an ACR call, the used selection rule redirected the selection rule redirected the call to a pilot.": "Таймер переповнення часу очікування перенаправив дзвінок на пілот, або у разі ACR-дзвінка, використано правило вибору, яке перенаправило дзвінок на пілот.",
  "The ringing overflow timer redirected the call to a pilot.": "Таймер переповнення дзвінка перенаправив дзвінок на пілот.",
  "The targeted pilot is closed, the call is redirected to an agent.": "Цільовий пілот закритий, дзвінок перенаправляється на оператора.",
  "The targeted pilot is saturated, the call is redirected to an agent.": "Цільовий пілот переповнений, дзвінок перенаправляється на оператора.",
  "The waiting time overflow timer redirected the call to an agent .": "Таймер переповнення часу очікування перенаправив дзвінок на оператора.",
  "The ringing overflow timer redirected the call to an agent.": "Таймер переповнення дзвінка перенаправив дзвінок на оператора.",
  "The targeted pilot is closed, the call is redirected to an address (not an acd device).": "Цільовий пілот закритий, дзвінок перенаправляється на адресу (не на пристрій ACD).",
  "The targeted pilot is saturated, the call is redirected to an address (not an acd device).": "Цільовий пілот переповнений, дзвінок перенаправляється на адресу (не на пристрій ACD).",
  "The waiting time overflow timer redirected the call to an address (not an acd device).": "Таймер переповнення часу очікування перенаправив дзвінок на адресу (не на пристрій ACD).",
  "The ringing overflow timer redirected the call to an address (not an acd device).": "Таймер переповнення дзвінка перенаправивдзвінок на адресу (не на пристрій ACD).",
  "The call was ringing an agent, another agent of the different processing group picked'up the call.": "Дзвінок дзвонив оператору, інший оператор із різної групи обробки відповів на дзвінок.",
  "The call was ringing an agent when a non monitored device picked?up the call.": "Дзвінок дзвонив оператору, коли його підхопив несупроводжуваний пристрій.",
  "An acd_call was transferred to a pilot by an agent.": "ACD-дзвінок був перенаправлений на пілота оператором.",
  "An acd_call was transferred to an agent of the same processing group by an agent.": "ACD-дзвінок був перенаправлений на оператора зі своєї ж групи обробки оператором.",
  "An acd_call was transferred to an agent belonging to a different processing group of agent?s one who performed the transfer": "ACD-дзвінок був перенаправлений на оператора, що належить до іншої групи обробки, аніж оператор, який виконав перенаправлення",
  "An acd_call was transferred by an agent to an address (non acd device).": "ACD-дзвінок був перенаправлений оператором на адресу (не на пристрій ACD).",
  "A non acd_call was transferred by an agent to a pilot.": "Не ACD-дзвінок був перенаправлений оператором на пілота.",
  "A non acd_call was transferred by an agent to an agent belonging to the same processing group.": "Не ACD-дзвінок був перенаправлений оператором на оператора, що належить до тієї ж групи обробки.",
  "A non acd_call was transferred by an agent to an other agent not belonging to the same processing group (or not assigned).": "Не ACD-дзвінок був перенаправлений оператором на іншого оператора, який не належить до тієї ж групи обробки (або не призначений).",
  "A non acd_call was transferred by an agent to an address (not an acd device).": "Не ACD-дзвінок був перенаправлений оператором на адресу (не на пристрій ACD).",
  "A non_acd call ringing an agent was picked?up by a non monitored device.": "Не ACD-дзвінок, який дзвонив оператору, був підхоплений несупроводжуваним пристроєм.",
  "Communication ends due to an abnormal cause : coupler of agent's set was unplugged, agent?s phone set was unplugged...).": "Комунікація закінчується внаслідок неприродної причини: вилучено з'єднувач набору оператора, вилучено телефонний набір оператора...).",
  "Called released the call (mainly an agent or a non monitored device).": "Викликаючий завершив дзвінок (головним чином, оператор або несупроводжуваний пристрій).",
  "Acd call rejected. An acd call is presented to a pilot, no resource is available to handle the call (See Note 1).": "ACD-дзвінок відхилено. ACD-дзвінок подається на пілота, немає доступних ресурсів для обробки дзвінка (див. Примітку 1).",
  "The call was ringing an agent, an other agent of the same processing group picked?up the call.": "Дзвінок дзвонив оператору, інший оператор зі тієї ж групи обробки підхопив дзвінок.",
  "An acd call is distributed on a resource which is not an agent.": "ACD-дзвінок розподіляється на ресурс, який не є оператором.",
  "An acd call is picked?up by a non assigned agent (the call becomes a non?acd call).": "ACD-дзвінок підхоплюється не призначеним оператором (дзвінок стає не-ACD дзвінком).",
  "An acd_call was transferred to a non assigned agent, the call becomes a non acd call": "ACD-дзвінок був перенаправлений на не призначеного оператора, дзвінок стає не-ACD дзвінком",
  "A call was released due to the agent's unplugged phone set": "Дзвінок було звільнено через відключення телефонного набору оператора",
  "A call connected to an IVR while waiting in queue (IVR in queue feature) was extracted from the queue by the IVR.": "Дзвінок, що підключений до IVR під час очікування у черзі (функція IVR у черзі), був вилучений з черги саме IVR.",
  "For an ACR call, a selection mode or an attribute list (skill, expert, character) change happened in waiting room.": "Для ACR-дзвінка в режимі очікування відбулась зміна режиму вибору або списку атрибутів (навички, експертність, характер).",
  "An ACR rotation happened, the new rang agent and the previous one belong to the same PG.": "Відбулась ротація ACR, новий обраний оператор і попередній належать до однієї й тієї ж групи обробки.",
  "An ACR rotation happened, the new rang agent and the previous one belong to two different PG.": "Відбулась ротація ACR, новий обраний оператор і попередній належать до двох різних груп обробки.",
  "Call is suspended.": "Дзвінок призупинено.",
  "Ringing rotation for an ACR call distributed to a dedicated pilot": "Обертання дзвінка для ACR, розподіленого на призначений пілот.",
  "An ACR call sent to a dedicated pilot (ACR networking) is not served and comes back on local node.": "ACR-дзвінок, надісланий на призначений пілот (мережевий ACR), не обслуговується і повертається на локальний вузол.",
  "An enquiry call is done towards an agent. When the caller releases this enquiry call by a transfert, communication ticket for th": "Запитувальний дзвінок адресований оператору. Коли викликаючий відпускає цей запитувальний дзвінок шляхом перенаправлення, комунікаційний квиток для нього",
  "Region": "Область",
  "District": "Район",
  "Community": "Громада",
  "Sector": "Сектор",
  "Type": "Тип",
  "Category": "Категорія",
  "Source": "Канал отримання",
  "Responsible": "Відповідальний",
  "Create date": "Дата створення",
  "PO name": "Назва ГО",
  "ticket created": "створено тікет",
  "changed status": "змінено статус",
  "added comment": "додано коментар",
  "Users": "Користувачі",
  "Add user": "Додати користувача",
  "First name": "Ім'я",
  "Last name": "Прізвище",
  "Email": "Електронна пошта",
  "Register user": "Реєстрація користувача",
  "Search user": "Пошук користувача",
  "Confirm password": "Підтвердіть пароль",
  "New user registration": "Реєстрація нового користувача",
  "Please input firts name.": "Будь ласка, введіть ім'я.",
  "Please input last name.": "Будь ласка, введіть прізвище.",
  "The input is not valid Email.": "Введена адреса електронної пошти недійсна.",
  "Please input Email.": "Будь ласка, введіть електронну пошту.",
  "Please input password.": "Будь ласка, введіть пароль.",
  "Password must be at least 8 characters long.": "Пароль має бути не менше 8 символів.",
  "Password must be at most 20 characters long.": "Пароль має бути не більше 20 символів.",
  "Password must contain at least one letter and one number.": "Пароль має містити хоча б одну букву та одне число.",
  "Please confirm your password.": "Будь ласка, підтвердіть ваш пароль.",
  "The new password that you entered do not match.": "Новий пароль, який ви ввели, не збігається.",
  "Thank you for your application!": "Дякуємо за ваше звернення!",
  "Your application number: ": "Номер вашого звернення: ",
  "Register another": "Зареєструвати ще",
  "Application registration": "Реєстрація звернення",
  "Select region": "Виберіть область",
  "Please select your district.": "Будь ласка, виберіть ваш район.",
  "Select district": "Виберіть район",
  "Territorial community": "Територіальна громада",
  "Please select your territorial community.": "Будь ласка, виберіть вашу територіальну громаду.",
  "Select territorial community": "Виберіть територіальну громаду",
  "Please select type of application.": "Будь ласка, виберіть тип звернення.",
  "Please select category of application.": "Будь ласка, виберіть категорію звернення.",
  "Please select sector of application.": "Будь ласка, виберіть сектор звернення.",
  "Please describe your application.": "Будь ласка, опишіть ваше звернення.",
  "Public organization name": "Назва громадської організації",
  "Please input your phone number.": "Будь ласка, введіть ваш номер телефону.",
  "Please input valid phone number in format +380xxxxxxxxx.": "Будь ласка, введіть дійсний номер телефону у форматі +380xxxxxxxxx.",
  "Register application": "Зареєструвати звернення",
  "Select sector": "Виберіть сектор",
  "Please input your name.": "Будь ласка, введіть ваше ім'я.",
  "Please select your region.": "Будь ласка, виберіть вашу область.",
  "Check application status": "Перевірити статус звернення",
  "Application ID": "Номер звернення",
  "Please input application ID.": "Будь ласка, введіть номер звернення.",
  "Format must be two latin letters and six digits (NR123456).": "Формат повинен бути дві латинські літери та шість цифр (NR123456).",
  "Application status:": "Статус звернення:",
  "The application with the provided registration number and phone number was not found": "Звернення з наданим номером та номером телефону не знайдено",
  "Check status": "Перевірити статус",
  "Please input phone number.": "Будь ласка, введіть номер телефону.",
  "Please input name.": "Будь ласка, введіть ім'я.",
  "Please select community." : "Будь ласка, виберіть громаду.",
  "Please select district.": "Будь ласка, виберіть район.",
  "Please select region.": "Будь ласка, виберіть область.",
  "Please select sector." : "Будь ласка, виберіть сектор.",
  "Please select type.": "Будь ласка, виберіть тип.",
  "Please select category.": "Будь ласка, виберіть категорію.",
  "Edit": "Редагувати",
  "Save": "Зберегти",
  "Cancel": "Скасувати",
  "Social Protection": "Соціальний захист",
  "Child Protection, Gender-based violence, Protection from Sexual Exploitation and Abuse": "Захист дітей, Гендерно зумовлене насильство, Захист від сексуальної експлуатації та насильства ",
  "Health": "Здоров'я",
  "Nutrition": "Харчування",
  "Water suplies": "Вода",
  "Other": "Міжгалузевий",
  "Vaccination": "Вакцинація",
  "Home Visiting Programme": "Програма домашніх візитів",
  "Spilno Cash Programme": "Грошова допомога Спільно",
  "Spilno Spots": "Точка Спільно",
  "No information": "Немає інформації",
  "Complain": "Скарга",
  "Gratitude": "Подяка",
  "Recommendations": "Рекомендації",
  "Other": "Інші питання",
  "Service": "Сервіс",
  "Provision": "Постачання",
  "Cash": "Готівка",
  "Subscription": "Підписка",
  "Education": "Освіта",
  "Create an Appeal": "Створити звернення",
  "Leave feedback about our work, services and help." : "Залиште зворотний зв'язок про нашу роботу, послуги та допомогу.",
  "Name of non-governmental organization": "Назва громадської організації",
  "Add photo": "Додати фото",
  "Search by description": "Пошук за описом",
  "Total": "Всього",
  "Resolving time": "Час вирішення",
  "Status of requests": "Статуси звернень",
  "Details": "Деталізація",
  "Statuses": "Статуси",
  "Sectors": "Сектори",
  "Statistics": "Статистика",
  "Welcome! You have submitted a request to our UNICEF Ukraine information support hotline.": "Вітаємо! Ви залишали звернення на нашій лінії інформаційної підтримки UNICEF Україна.",
  "We would appreciate it if you could answer a few questions:": "Будемо вдячні за відповіді на декілька запитань:",
  "1. How satisfied are you with the solution or response you received from our team through the hotline?": "1.	Наскільки задоволені ви рішенням або відповіддю, яку отримали від нашої команди через гарячу лінію?",
  "2. How likely are you to recommend our hotline for feedback to a friend or colleague? Please rate on a scale from 0 to 10, where “0” means “Not at all likely” and “10” means “Definitely will recommend”.":"2. Наскільки ймовірно ви порекомендуєте нашу гарячу лінію для зворотного зв'язку вашому другові чи колезі? Будь ласка, оцініть на шкалі від 0 до 10, де «0» - Не порекомендую взагалі, а «10» -Точно порекомендую.",
  "Very dissatisfied": "Дуже незадоволені",
	"Dissatisfied": "Незадоволені",
	"Neutral": "Нейтрально",
	"Satisfied": "Задоволені",
	"Very satisfied": "Дуже задоволені",
  "Please make your choice.": "Будь ласка, зробіть свій вибір.",
  "Thank you for your feedback.": "Дякуємо за оцінку.",
  "You have already left feedback.": "Ви вже залишали відгук.",
  "There is no such appeal.": "Такого звернення не існує.",
  "who provided help (if exists)": "яка надала допомогу (якщо є)",
  "Please select theme of application.": "Будь ласка, виберіть тему звернення.",
  "Upload": "Завантажити",
  "Select theme": "Виберіть тему",
  "Sectors and Regions": "Сектори та області",
}

export { en, ua };