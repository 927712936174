'use client'

import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Table, Typography, Button, Input, Space, Modal, Form, Row, Tree, Flex, Checkbox } from 'antd';
import { SearchOutlined, UserAddOutlined, InfoCircleOutlined, EditOutlined } from '@ant-design/icons';
import { 
    useGetUsersQuery,
    useGetUserSectorQuery,
    useGetOblastQuery,
    useAddUserMutation,
    useEditUserAccessMutation,
    useSetUserSubscriptionMutation,
} from '../../utils/api/ticketsApi';
import './UsersPage.css';

const { Title, Text, Link } = Typography;

const UsersPage = () => {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState('ua');
    const [form] = Form.useForm();
    const [registerForm] = Form.useForm();

    const { data: usersData, refetch: refetchUsersData, isLoading: usersDataIsLoading, isFetching: usersDataIsFetching } = useGetUsersQuery();
    const { data: sectorsData } = useGetUserSectorQuery();
    const { data: regionsData } = useGetOblastQuery();

    const [filterText, setFilterText] = useState('');
    const [filteredUsers, setFilteredUsers] = useState(usersData);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserAccess, setSelectedUserAccess] = useState([]);

    const [editUserAccess, {isLoading: editUserAccessIsLoading, isFetching: editUserAccessIsFething, isSuccess: editUserAccessIsSuccess, isError: editUserAccessIsError}] = useEditUserAccessMutation()

    const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
    const [addUser, {result: addUserResult, isLoading: addUserIsLoading, isFetching: addUserIsFetching, isSuccess: addUserIsSuccess}] = useAddUserMutation();

    const [setUserSubscription, {result: setUserSubscriptionResult, isLoading: setUserSubscriptionLoading, isFetching: setUserSubscriptionFetching, isSuccess: setUserSubscriptionIsSuccess}] = useSetUserSubscriptionMutation();

    useEffect(() => {
        if (usersData) {
            setFilteredUsers(usersData);
        }
    }, [usersData]);

    useEffect(() => {
        if (filterText) {
            setFilteredUsers(usersData?.filter(user => 
                user.username.toLowerCase().includes(filterText.toLowerCase()) ||
                user.email.toLowerCase().includes(filterText.toLowerCase()) ||
                user.first_name.toLowerCase().includes(filterText.toLowerCase()) ||
                user.last_name.toLowerCase().includes(filterText.toLowerCase())
            ));
        } else {
            setFilteredUsers(usersData);
        }
    }, [filterText, usersData]);

    const handleEditUser = (user) => {
        setSelectedUser(user);
        form.setFieldsValue({
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
        });
        setIsModalVisible(true);
    };

    const onFinish = async (values) => {
        await editUserAccess({user_id: selectedUser?.id, sector_oblast: selectedUserAccess}).then(refetchUsersData())
    };

    const convertToTree = (data) => {
        return sectorsData?.map((sector) => ({
            title: i18n.language==="ua" ? sector.name : sector.name_en,
            key: `sector-${sector.id}`,
            children: regionsData?.map((region) => {
                // Check if the user is responsible for this sector and region
                const responsibleSector = data.find((s) => s.sector.id === sector.id);
                const isChecked = responsibleSector
                    ? responsibleSector.sector.oblast_list.some((oblast) => oblast.id === region.id)
                    : true;
                return {
                    title: i18n.language==="ua" ? region.name : region.name_en,
                    key: `sector-${sector.id}-region-${region.id}`,
                    checked: true,
                };
            }),
        }));
    };


    const getChecked = (data) => {
        let checked = [];
        sectorsData?.map((sector) => ({
            title: sector.name,
            key: `sector-${sector.id}`,
            children: regionsData?.map((region) => {
                // Check if the user is responsible for this sector and region
                const responsibleSector = data.find((s) => s.sector.id === sector.id);
                const isChecked = responsibleSector
                    ? responsibleSector.sector.oblast_list.some((oblast) => oblast.id === region.id)
                    : false;
                
                if(isChecked){checked.push(`sector-${sector.id}-region-${region.id}`)}
                return {
                    title: region.name,
                    key: `sector-${sector.id}-region-${region.id}`,
                    checked: true,
                };
            }),
        }));
        return checked;
    };

    const onCheck = (checkedKeys, info) => {
        const checked = checkedKeys.map((row) => {
            const [__, sectorId, _, regionId] = row.split('-');
            if (regionId !== undefined) {
                return {sector_id: parseInt(sectorId), oblast_id: parseInt(regionId)};
            }
            return undefined;
        }).filter(item => item !== undefined);
        setSelectedUserAccess(checked);
    };

    const handleSubscription = (username, subscription) => {
        setUserSubscription({username, subscription});
        const tempFilteredUsers = filteredUsers.map(user => {
            if (user.username === username) {
                return {...user, subscription};
            }
            return user;
        });
        setFilteredUsers(tempFilteredUsers);
    }

    const columns = [
        {
            title: t('Username'),
            dataIndex: 'username',
            key: 'username',
            render: (text) => <Link>{text}</Link>,
        },
        {
            title: t('First name'),
            dataIndex: 'first_name',
            key: 'first_name',
        },
        {
            title: t('Last name'),
            dataIndex: 'last_name',
            key: 'last_name',
        },
        {
            title: t('Email'),
            dataIndex: 'email',
            key: 'email',
            render: (text) => <Link>{text}</Link>,
        },
        {
            title: t('Subscription'),
            dataIndex: 'subscription',
            key: 'subscription',
            width: '5%',
            render: (text, record) => (
                <Flex justify='center' align='center'>
                    <Checkbox
                        checked={record.subscription}
                        // disabled={setUserSubscriptionFetching}
                        onChange={(e)=> {
                            handleSubscription(record.username, e?.target?.checked);
                        }}
                    />
                </Flex>
            ),
        },
        {
            title: t("Sectors and Regions"),
            key: 'actions',
            width: '12%',
            render: (_, record) => (
                <Flex justify='center' align='center'>
                    <Button icon={<EditOutlined />} onClick={() => handleEditUser(record)} />
                </Flex>
            ),
        },
    ];

    const registerFormItemLayout = {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 8,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 16,
          },
        },
      };

    return (
        <Row className="users-page-content">
            <Space direction="vertical" size="large" style={{ width: '80%' }}>
                <Space>
                    <Input
                        placeholder={t('Search user')}
                        prefix={<SearchOutlined />}
                        onChange={(e) => setFilterText(e.target.value)}
                    />
                    <Button
                        type="primary"
                        icon={<UserAddOutlined />}
                        onClick={() => {
                            setSelectedUser(null);
                            form.resetFields();
                            setIsRegisterModalVisible(true);
                        }}
                    >
                        {t('Add user')}
                    </Button>
                </Space>
                <Table
                    size='small'
                    rowKey="username"
                    dataSource={filteredUsers}
                    columns={columns}
                    pagination={false}
                />
            </Space>
            <Modal

                open={isModalVisible}
                onCancel={() => {
                    setIsModalVisible(false);
                    form.resetFields();
                }}
                footer={null}
                destroyOnClose
                width={800}
                closable={false}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        first_name: '',
                        last_name: '',
                        email: ''
                    }}
                    {...registerFormItemLayout}
                >
                    <Form.Item
                        name="first_name"
                        label={t("First name")}
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        name="last_name"
                        label={t("Last name")}
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label={t("Email")}
                    >
                        <Input disabled />
                    </Form.Item>
                        <Form.Item
                            name="access"
                            label={t("Sectors and Regions")}
                        >
                            <Tree
                                checkable
                                selectable={false}
                                showLine
                                onCheck={onCheck}
                                treeData={convertToTree(selectedUser?.responsible || [])}
                                defaultCheckedKeys={getChecked(selectedUser?.responsible || [])}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Space
                                direction='horizontal'
                                align='center'
                            >
                                <Button 
                                    type="primary" 
                                    htmlType="submit" 
                                    loading={
                                        editUserAccessIsLoading ||
                                        editUserAccessIsFething ||
                                        usersDataIsLoading ||
                                        usersDataIsFetching
                                    }
                                    >
                                    {t("Save")}
                                </Button>
                                <Button type="primary" danger onClick={()=> setIsModalVisible(false)}>
                                    {t("Cancel")}
                                </Button>
                            </Space>
                        </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="New user registration"
                closable={false} 
                open={isRegisterModalVisible} 
                onCancel={()=>setIsRegisterModalVisible(false)} 
                footer={null} 
            >
                <Form 
                    {...registerFormItemLayout}
                    name="register"
                    form={registerForm}
                    onFinish={(values)=>addUser(values)}
                >
                    <Form.Item
                        label={t("First name")}
                        name="first_name"
                        rules={[{ required: true, message: t('Please input firts name.') }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("Last name")}
                        name="last_name"
                        rules={[{ required: true, message: t('Please input last name.') }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label={t("Email")}
                        rules={[
                        {
                            type: 'email',
                            message: t('The input is not valid Email.'),
                        },
                        {
                            required: true,
                            message: t('Please input Email.'),
                        },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label={t("Password")}
                        rules={[
                        {
                            required: true,
                            message: t('Please input password.'),
                        },
                        { min: 8, message: t('Password must be at least 8 characters long.') },
                        { max: 20, message: t('Password must be at most 20 characters long.') },
                        { pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, message: t('Password must contain at least one letter and one number.') },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label={t("Confirm password")}
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                        {
                            required: true,
                            message: t('Please confirm your password.'),
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('The new password that you entered do not match.')));
                            },
                        }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item>
                        <Space
                            direction='horizontal'
                            align='center'
                        >
                            <Button 
                                type="primary" 
                                htmlType="submit"
                                loading={addUserIsLoading}
                            >
                                {t("Register user")}
                            </Button>
                            <Button
                                type="primary"
                                danger
                                onClick={()=>setIsRegisterModalVisible(false)}
                                >
                                {t("Cancel")}   
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </Row>
    );
}

export default UsersPage