import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import dayjs from "dayjs";


const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const ticketsApi = createApi({
  reducerPath: 'ticketsApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getState().auth.access;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
    timeout: 5000,
    onQueryError: (error) => {
      console.error('An error occurred:', error);
    },
  }),
  tagTypes: ['users'],
  endpoints: (builder) => ({
    getTickets: builder.query({
      query: ({
          offset = 0, limit = 10, created_at_start, created_at_end, phone, oblastId,
          type_id, category_id, source_id, responsible, status_id, description, is_test = false, order_by = "-created_at"
      }) => {
          const dateStart = created_at_start ? `&created_at_start=${created_at_start} 00:00:00` : '';
          const dateEnd = created_at_end ? `&created_at_end=${created_at_end} 23:59:59` : '';
          const phoneQuery = phone ? `&phone=${encodeURIComponent(phone)}` : '';
          const oblastIdQuery = oblastId ? `&oblast_id=${oblastId}` : '';
          const typeIdQuery = type_id ? `&type_id=${type_id}` : '';
          const categoryIdQuery = category_id ? `&category_id=${category_id}` : '';
          const sourceIdQuery = source_id ? `&source_id=${source_id}` : '';
          const responsibleQuery = responsible ? `&responsible=${responsible}` : '';
          const statusIdQuery = status_id ? `&status_id=${status_id}` : '';
          const descriptionQuery = description ? `&description=${description}` : '';
          const isTest = `&is_test=${false}`;
          const orderQuery = `&order_by=${order_by}`;
  
          return `tickets?offset=${offset}&limit=${limit}${dateStart}${dateEnd}${phoneQuery}${oblastIdQuery}${typeIdQuery}${categoryIdQuery}${sourceIdQuery}${responsibleQuery}${statusIdQuery}${descriptionQuery}${isTest}${orderQuery}`;
      },
      keepUnusedDataFor: 0,
      transformResponse: (response) => {
        const tickets = response.tickets.map((ticket) => {
            return {
                id: ticket.id,
                call_date: dayjs(ticket.call_date).format("DD.MM.YYYY HH:mm:ss"),
                created_at: dayjs(ticket.created_at).format("DD.MM.YYYY HH:mm:ss"),
                description: ticket?.description,
                status: ticket?.status,
                statusId: ticket?.status?.id,
                responsible: ticket.responsible,
                type: ticket.type,
                category: ticket.category,
                source: ticket.source,
                sector: ticket.sector,
                oblast: ticket.oblast,
                region: ticket.region,
                phone: ticket.phone,
                audio_link: ticket.audio_link,
                change_log: ticket.change_log,
                status_changes: ticket.status_changes.map(change => ({
                    status: change.status?.name,
                    changed_by: change.changed_by?.username,
                    changed_at: dayjs(change.changed_at).format("DD.MM.YYYY HH:mm:ss"),
                    previous_status: change.previous_status?.name
                })),
                is_test: ticket?.is_test,
            };
        });
        return { tickets: tickets, count: response.count };
      },
      throws: true,
    }),
    getTicketById: builder.query({
      query: (ticketId) => `tickets/${ticketId}`,
      transformResponse: (response) => {
        response.status.name = response?.status?.name;
        const status_changes_list = [{
                                      date: response?.call_date, 
                                      status: response.status, 
                                      statusId: 1,
                                      username: null
                                    }];
        const status_changes = response.status_changes.map((row) => ({
                                      date: row.changed_at, 
                                      status: row.status, 
                                      statusId: row.status.id,
                                      username: row.changed_by.username
                                    }));
        response.status_changes_list = status_changes_list.concat(status_changes);
        return response
      },
      throws: true,
    }),
    addComment: builder.mutation({
      query: ({ticketId, comment:text}) => ({
        url: `tickets/${ticketId}/comments`,
        method: 'POST',
        body: JSON.stringify({ "text": text }),
      }),
      throws: true,
    }),
    uploadFile: builder.mutation({
      query: ({ ticketId, file }) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `tickets/${ticketId}/files/upload`,
          method: 'POST',
          body: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
      throws: true,
    }),
    changeStatus: builder.mutation({
      query: ({ ticketId, newStatusId }) => ({
        url: `tickets/${ticketId}/status`,
        method: 'PATCH',
        body: JSON.stringify({ "status_id": newStatusId }),
      }),
      throws: true,
    }),
    updateTicket: builder.mutation({
      query: ({ ticketId, category, description, raion, first_name, hromada, phone, public_org, oblast, sector, type }) => ({
        url: `tickets/${ticketId}`,
        method: 'PATCH',
        body: JSON.stringify({ category, description, raion, first_name, hromada, phone, public_org, oblast, sector, type }),
      }),
      throws: true,
    }),
    getManagers: builder.query({
      query: () => 'manager',
      transformResponse: (response) => {
        return response.map((manager) => ({
          value: manager.username,
          label: manager.username,
        }));
      },
      throws: true,
    }),
    getThemes: builder.query({
      query: () => 'themes',
      transformResponse: (response) => {
        return response.map((theme) => ({
          value: theme.id,
          // label: theme.description,
        }));
      },
      throws: true,
    }),
    getStatuses: builder.query({
      query: () => 'statuses',
      transformResponse: (response) => {
        return response.map((status) => ({
          value: status.id,
          label: status.name,
        }));
      },
      throws: true,
    }),
    getTypes: builder.query({
      query: () => 'types',
      transformResponse: (response) => {
        return response.map((type) => ({
          value: type.id,
          label: type.name,
          id: type.id,
          name: type.name,
          name_en: type.name_en
        }));
      },
      throws: true,
    }),
    getCategories: builder.query({
      query: () => 'categories',
      transformResponse: (response) => {
        return response.map((category) => ({
          value: category.id,
          label: category.name,
          id: category.id,
          name: category.name,
          name_en: category.name_en
        }));
      },
      throws: true,
    }),
    getSectors: builder.query({
      query: () => 'sectors',
      transformResponse: (response) => {
        return response.map((sector) => ({
          value: sector.id,
          label: sector.name,
          id: sector.id,
          name: sector.name,
          name_en: sector.name_en
        }));
      },
      throws: true,
    }),
    getSources: builder.query({
      query: () => 'sources',
      transformResponse: (response) => {
        
        // return response.map((source) => ({
        //   value: source.id,
        //   label: source.name,
        // }));
        return (
          [
            {
              value: 1,
              label: "Дзвінок",
              id: 1,
              name: "Дзвінок",
              name_en: "Call"
            },
            {
              value: 2,
              label: "Веб-форма",
              id: 2,
              name: "Веб-форма",
              name_en: "Web-form"
            }
          ]
        )
      },
      throws: true,
    }),
    getRegions: builder.query({
      query: () => 'address/regions',
      transformResponse: (response) => {
        return response.map((region) => ({
          value: region,
          label: region
        }));
      },
      throws: true,
    }),
    getOblast: builder.query({
      query: () => 'address/oblast',
      transformResponse: (response) => {
        return response.map((oblast) => ({
          value: oblast.id,
          label: oblast.name,
          label_en: oblast.name_en,
          id: oblast.id,
          name: oblast.name,
          name_en: oblast.name_en
        }));
      },
      throws: true,
    }),
    getRaion: builder.query({
      query: ({oblastId}) => {
        return `address/raion?oblast_id=${oblastId}`;
      },
      transformResponse: (response) => {
        return response.map((raion) => ({
          value: raion.id,
          label: raion.name,
          label_en: raion.name_en,
        }));
      },
      throws: true,
    }),
    getHromada: builder.query({
      query: ({raionId}) => {
        return `address/hromada?raion_id=${raionId}`;
      },
      transformResponse: (response) => {
        return response.map((hromada) => ({
          value: hromada.id,
          label: hromada.name,
          label_en: hromada.name_en,
        }));
      },
      throws: true,
    }),
    getDistricts: builder.query({
      query: ({region}) => {
        return `address/districts?region=${region}`;
      },
      transformResponse: (response) => {
        return response.map((district) => ({
          value: district,
          label: district,
        }));
      },
      throws: true,
    }),
    getOtgs: builder.query({
      query: ({region, district}) => {
        return `address/otgs?region=${region}&district=${district}`;
      },
      transformResponse: (response) => {
        return response.map((otg) => ({
          value: otg,
          label: otg,
        }));
      },
      throws: true,
    }),
    addUser: builder.mutation({
      query: ({ first_name, last_name, email, password, confirm }) => ({
        url: `manager/create`,
        method: 'POST',
        body: JSON.stringify({ first_name, last_name, email, password }),
      }),
      invalidatesTags: ['users'],
      throws: true,
    }),
    getUsers: builder.query({
      query: () => 'manager',
      transformResponse: (response) => {
        return response.map((user) => ({
          ...user,
        }));
      },
      providesTags: ['users'],
      throws: true,
    }),
    setUserAccess: builder.mutation({
      query: ({ user_id, sector_id, region_id }) => ({
        url: 'address/regions',
        method: 'POST',
        body: JSON.stringify({ user_id, sector_id, region_id }),
      }),
      transformResponse: (response) => {
        return [];
      },
      throws: true,
    }),
    delUserAccess: builder.mutation({
      query: ({ user_id, sector_id, region_id }) => ({
        url: 'address/regions',
        method: 'DELETE',
        body: JSON.stringify({ user_id, sector_id, region_id }),
      }),
      transformResponse: (response) => {
        return [];
      },
      throws: true,
    }),
    getUsersRegions: builder.query({
      query: () => 'address/regions',
      // transformResponse: (response) => {
      //   return [...response].map((region) => ({
      //     value: region.id,
      //     label: region.name,
      //   }));
      // },
      throws: true,
    }),
    setUserRegion: builder.mutation({
      query: ({ username, region }) => ({
        url: `manager/set_region`,
        method: 'POST',
        body: JSON.stringify({username, region}),
      }),
      throws: true,
    }),
    getUserSector: builder.query({
      query: () => `sectors`,
      transformResponse: (response) => {
        return response.map((sector) => ({
          value: sector.id,
          label: sector.name,
          id: sector.id,
          name: sector.name,
          name_en: sector.name_en,
        }));
      },
      throws: true,
    }),
    addUserSector: builder.mutation({
      query: ({ username, sector }) => ({
        url: `manager/sectors/add`,
        method: 'POST',
        body: JSON.stringify({username, sector}),
      }),
      throws: true,
    }),
    removeUserSector: builder.mutation({
      query: ({ username, sector }) => ({
        url: `manager/sectors/remove`,
        method: 'POST',
        body: JSON.stringify({username, sector}),
      }),
      throws: true,
    }),
    editUserAccess: builder.mutation({
      query: ({ user_id, sector_oblast }) => ({
        url: `manager/responsible/update`,
        method: 'POST',
        body: JSON.stringify({ user_id, sector_oblast}),
      }),
      throws: true,
    }),
    createTicket: builder.mutation({
      query: ({ first_name, oblast, raion, hromada, type, sector, category, description, public_org, phone, recaptcha, files }) => {
        const formData = new FormData();
        formData.append('data', JSON.stringify({first_name, oblast, raion, hromada, type, sector, category, description, public_org, phone, recaptcha }));
        files.forEach((file) => {
          formData.append('files', file.originFileObj);
        });
        return {
          url: 'tickets',
          method: 'POST',
          body: formData,
        };
      },
      throws: true,
    }),
    checkStatus: builder.mutation({
      query: ({ uid, phone, recaptcha }) => ({
        url: `ticket/status/check`,
        method: 'POST',
        body: JSON.stringify({ uid, phone, recaptcha }),
      }),
      throws: true,
    }),
    setUserSubscription: builder.mutation({
      query: ({ username, subscription }) => ({
        url: `manager/set_subscription`,
        method: 'POST',
        body: JSON.stringify({username, subscription}),
      }),
      throws: true,
    }),
    submitFeedback: builder.mutation({
      query: (feedback) => ({
        url: '/feedback',
        method: 'POST',
        body: feedback,
      }),
    }),
    getTicketFeedback: builder.query({
      query: ({uuid}) => `feedback/${uuid}`,
      throws: true,
      keepUnusedDataFor: 0,
    }),
    getStatistics: builder.query({
      query: ({date_start, date_end}) => `stat/status_sector?date_start=${date_start}&date_end=${date_end}`,
      throws: true,
      keepUnusedDataFor: 0,
    }),
    getStatisticsResponsibleSector: builder.query({
      query: ({date_start, date_end}) => `stat/responsible_sector?date_start=${date_start}&date_end=${date_end}`,
      throws: true,
      keepUnusedDataFor: 0,
    }),
    getStatisticsResponsibleStatus: builder.query({
      query: ({date_start, date_end}) => `stat/responsible_status?date_start=${date_start}&date_end=${date_end}`,
      throws: true,
      keepUnusedDataFor: 0,
    }),
    getStatisticsResponsibleTime: builder.query({
      query: ({ date_start, date_end }) => `stat/resolving_time?date_start=${date_start}&date_end=${date_end}`,
      transformResponse: (response) => {
        return {
          data: response.data.map((row) => ({
            responsible: {
              id: row.user_id,
              username: row.username,
              last_name: row.last_name
            },
            time: parseFloat(row.average_time)
          }))
        };
      },
      throws: true,
      keepUnusedDataFor: 0,
    }),
    getStatistics2: builder.query({
      query: () => `sectors`,
      transformResponse: (response) => {
        return {
          "data": [
            { "Статус": "Нове", "Сектор": "Соціальний захист", "Кількість": 5 },
            { "Статус": "Нове", "Сектор": "Захист дітей, GBVІЕ, PSEA", "Кількість": 1 },
            { "Статус": "Нове", "Сектор": "Здоров'я", "Кількість": 2 },
            { "Статус": "Нове", "Сектор": "Харчування", "Кількість": 5 },
            { "Статус": "Нове", "Сектор": "Вода", "Кількість": 5 },
            { "Статус": "Нове", "Сектор": "Міжгалузевий", "Кількість": 5 },
            { "Статус": "Нове", "Сектор": "ВПЛ", "Кількість": 5 },
            { "Статус": "Нове", "Сектор": "Грошова допомога Спільно", "Кількість": 2 },
            { "Статус": "Нове", "Сектор": "Точка Спільно", "Кількість": 0 },
        
            { "Статус": "В роботі", "Сектор": "Соціальний захист", "Кількість": 15 },
            { "Статус": "В роботі", "Сектор": "Захист дітей, GBVІЕ, PSEA", "Кількість": 25 },
            { "Статус": "В роботі", "Сектор": "Здоров'я", "Кількість": 13 },
            { "Статус": "В роботі", "Сектор": "Харчування", "Кількість": 25 },
            { "Статус": "В роботі", "Сектор": "Вода", "Кількість": 4 },
            { "Статус": "В роботі", "Сектор": "Міжгалузевий", "Кількість": 6 },
            { "Статус": "В роботі", "Сектор": "ВПЛ", "Кількість": 4 },
            { "Статус": "В роботі", "Сектор": "Грошова допомога Спільно", "Кількість": 69 },
            { "Статус": "В роботі", "Сектор": "Точка Спільно", "Кількість": 5 },
        
            { "Статус": "Потребує уточнення", "Сектор": "Соціальний захист", "Кількість": 25 },
            { "Статус": "Потребує уточнення", "Сектор": "Захист дітей, GBVІЕ, PSEA", "Кількість": 12 },
            { "Статус": "Потребує уточнення", "Сектор": "Здоров'я", "Кількість": 53 },
            { "Статус": "Потребує уточнення", "Сектор": "Харчування", "Кількість": 6 },
            { "Статус": "Потребує уточнення", "Сектор": "Вода", "Кількість": 5 },
            { "Статус": "Потребує уточнення", "Сектор": "Міжгалузевий", "Кількість": 5 },
            { "Статус": "Потребує уточнення", "Сектор": "ВПЛ", "Кількість": 1 },
            { "Статус": "Потребує уточнення", "Сектор": "Грошова допомога Спільно", "Кількість": 52 },
            { "Статус": "Потребує уточнення", "Сектор": "Точка Спільно", "Кількість": 2 },
        
            { "Статус": "Відхилено", "Сектор": "Соціальний захист", "Кількість": 10 },
            { "Статус": "Відхилено", "Сектор": "Захист дітей, GBVІЕ, PSEA", "Кількість": 18 },
            { "Статус": "Відхилено", "Сектор": "Здоров'я", "Кількість": 1 },
            { "Статус": "Відхилено", "Сектор": "Харчування", "Кількість": 1 },
            { "Статус": "Відхилено", "Сектор": "Вода", "Кількість": 1 },
            { "Статус": "Відхилено", "Сектор": "Міжгалузевий", "Кількість": 2 },
            { "Статус": "Відхилено", "Сектор": "ВПЛ", "Кількість": 5 },
            { "Статус": "Відхилено", "Сектор": "Грошова допомога Спільно", "Кількість": 5 },
            { "Статус": "Відхилено", "Сектор": "Точка Спільно", "Кількість": 6 },
        
            { "Статус": "Завершено", "Сектор": "Соціальний захист", "Кількість": 0 },
            { "Статус": "Завершено", "Сектор": "Захист дітей, GBVІЕ, PSEA", "Кількість": 2 },
            { "Статус": "Завершено", "Сектор": "Здоров'я", "Кількість": 3 },
            { "Статус": "Завершено", "Сектор": "Харчування", "Кількість": 5 },
            { "Статус": "Завершено", "Сектор": "Вода", "Кількість": 5 },
            { "Статус": "Завершено", "Сектор": "Міжгалузевий", "Кількість": 5 },
            { "Статус": "Завершено", "Сектор": "ВПЛ", "Кількість": 2 },
            { "Статус": "Завершено", "Сектор": "Грошова допомога Спільно", "Кількість": 5 },
            { "Статус": "Завершено", "Сектор": "Точка Спільно", "Кількість": 2 }
          ]
        }
      },
      throws: true,
    }),
  }),
});

export const { 
    useGetTicketsQuery,
    useGetTicketByIdQuery,
    useAddCommentMutation,
    useUploadFileMutation,
    useChangeStatusMutation,
    useGetThemesQuery,
    useGetStatusesQuery,
    useGetTypesQuery,
    useGetCategoriesQuery,
    useGetSectorsQuery,
    useGetSourcesQuery,
    useGetManagersQuery,
    useGetRegionsQuery,
    useGetDistrictsQuery,
    useGetOtgsQuery,
    useGetOblastQuery,
    useGetRaionQuery,
    useGetHromadaQuery,
    useAddUserMutation,
    useGetUsersQuery,
    useGetUsersRegionsQuery,
    useSetUserRegionMutation,
    useGetUserSectorQuery,
    useAddUserSectorMutation,
    useSetUserAccessMutation,
    useDelUserAccessMutation,
    useEditUserAccessMutation,
    useRemoveUserSectorMutation,
    useCreateTicketMutation,
    useCheckStatusMutation,
    useUpdateTicketMutation,
    useSetUserSubscriptionMutation,
    useSubmitFeedbackMutation,
    useGetTicketFeedbackQuery,
    useGetStatisticsQuery,
    useGetStatistics2Query,
    useGetStatisticsResponsibleTimeQuery,
    useGetStatisticsResponsibleSectorQuery,
    useGetStatisticsResponsibleStatusQuery,
    
} = ticketsApi;